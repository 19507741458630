import React, { useState } from "react";
import InputSearch from "../../../common/components/InputSearch";
import { Button, Select, Table } from "antd";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../../../common/layout/CustomHeader";
// import { renderNavLink } from '../components/NavLink'
import { useTrainers } from "../hooks/useTrainers";
import { useCourses } from "../hooks/useListCourses";
import { debounce } from "lodash";
import { useApplicationStore } from "../../../store/applicationStore";

const ListCourses = () => {
  const navigate = useNavigate();
  const trainersQuery = useTrainers();

  // const [openDrawer, setOpenDrawer] = useState(false);
  const [courseSearch, setCourseSearch] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [trainerSelected, setTrainerSelected] = useState(null);
  const courses = useCourses({
    limit: itemsPerPage,
    page: selectedPage,
    search: courseSearch,
    trainer_id:
      trainerSelected === "All" || !trainerSelected ? null : trainerSelected,
  });

  const handleCourseSearch = debounce((value) => {
    setCourseSearch(
      value?.target?.value === null ||
        value?.target?.value?.trim() === "" ||
        value?.target?.value === undefined
        ? null
        : value?.target?.value
    );
  }, 1000);

  const handlePageChange = (page) => {
    setSelectedPage(page);
  };
  const handlePageSizeChange = (current, size) => {
    setSelectedPage(1);
    setItemsPerPage(size);
  };

  const columns = [
    {
      title: "Skill Name",
      dataIndex: "skill_name",
      key: "name",
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: "Trainer",
      dataIndex: "trainer_name",
      key: "trainer",
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: "Skill Level",
      dataIndex: "level_proficiency",
      key: "level_proficiency",
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          className="-ml-5"
          onClick={() => navigate(`/admin/courses/review/${record.id}`)}
        >
          Review
        </Button>
      ),
    },
  ];

  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);

  return (
    <div>
      <div className="ml-[-16.5rem]">
        <CustomHeader />
      </div>

      <p className="text-xl font-extrabold text-backdrop mb-3">
        Publish Requests
      </p>

      <div className="grid grid-cols-12 gap-7 mt-7">
        <div className="col-span-9">
          <InputSearch
            value={courseSearch}
            handleSearchChange={handleCourseSearch}
          />
        </div>
        <div className="col-span-3">
          <Select
            loading={trainersQuery?.isLoading}
            placeholder="Select Trainer"
            className="w-full"
            allowClear
            value={trainerSelected}
            onChange={(value) => setTrainerSelected(value)}
            options={
              trainersQuery?.data?.data?.data?.data
                ? trainersQuery?.data?.data?.data?.data.map((trainer) => ({
                    value: trainer?.id,
                    label: trainer?.first_name + " " + trainer?.last_name,
                  }))
                : []
            }
          />
        </div>
      </div>

      {/* TABLE */}
      <div className="admin-table">
        <Table
          columns={columns}
          dataSource={courses.data?.data?.data?.list || []}
          rowKey="id"
          className="mt-4 border"
          loading={courses.isLoading}
          pagination={{
            current: selectedPage,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            total: courses?.data?.data?.total_data,
            pageSize: itemsPerPage,
            showSizeChanger: true,
            defaultPageSize: 20,
            pageSizeOptions: [20, 50],
          }}
        />
      </div>
    </div>
  );
};

export default ListCourses;
