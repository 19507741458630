import React from "react"
import Cardv2 from "./CardDescription"
import { LessonCard } from "./LessonCard.tsx"

const Module = ({ existingData, newData }) => {
  return (
    <div>
      {/* module title */}
      <Cardv2
        name={"recap"}
        existingData={existingData?.overview?.recap}
        newData={newData?.overview?.recap}
      />
      {/* intoduction */}
      <Cardv2
        name={"introduction"}
        existingData={existingData?.overview?.introduction}
        newData={newData?.overview?.introduction}
      />
      {/* lesson */}
      {newData?.lessons?.map((lesson, index) => {
        let existingLesson = null
        if (
          newData?.lessons?.length === 1 &&
          existingData?.lessons?.length === 1
        ) {
          existingLesson = existingData?.lessons[0]
        } else {
          existingLesson = existingData?.lessons?.find(
            (itm) => itm.id === lesson.id
          )
        }
        return (
          <LessonCard
            key={index}
            name={`lesson ${index + 1}`}
            newData={lesson.description}
            existingData={existingLesson?.description}
            newVideo={lesson?.video}
            oldVideo={existingLesson?.video}
            newPdf={lesson?.pdf}
            oldPdf={existingLesson?.pdf}
          />
        )
      })}
      {/* resources */}
      <Cardv2
        name={"resources"}
        existingData={existingData?.conceptual_supports?.resources}
        newData={newData?.conceptual_supports?.resources}
      />
      {/* glossary */}
      <Cardv2
        name={"glossary"}
        existingData={existingData?.conceptual_supports?.glossary}
        newData={newData?.conceptual_supports?.glossary}
      />
      {/* reflection */}
      <Cardv2
        name={"reflection"}
        existingData={existingData?.conceptual_supports?.reflection}
        newData={newData?.conceptual_supports?.reflection}
      />
    </div>
  )
}

export default Module
