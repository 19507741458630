import React from "react"
import { Tabs } from "antd"
import { BitmovinPlayer } from "./BitmovinPlayer"

type Video = {
  dash: string
  status: "FINISHED" | string
}

interface PdfResponse {
  url: string
  name: string
}
interface LessonCardProps {
  name: string
  existingData?: string | null
  newData?: string | null
  newVideo?: Video | null
  oldVideo?: Video | null
  newPdf?: PdfResponse[] | null
  oldPdf?: PdfResponse[] | null
}


export const LessonCard: React.FC<LessonCardProps> = ({
  name,
  existingData = "--none--",
  newData = "--none--",
  newVideo = null,
  oldVideo = null,
  newPdf = null,
  oldPdf = null,
}) => {

  console.log('newPdf', newPdf)


  return (
    <div className='my-5'>
      <p className='mb-3 capitalize'>{name}</p>
      <div className='w-full'>
        <Tabs
          type='card'
          destroyInactiveTabPane={false}
          tabBarStyle={{
            letterSpacing: "0.02em",
            height: "40px",
          }}
          items={[
            {
              label: `Text`,
              key: "text",
              children: (
                <>
                  <div className='grid grid-cols-2 gap-5 mt-4'>
                    <div
                      className='border min-h-[80px] rounded-sm p-2'
                      dangerouslySetInnerHTML={{
                        __html: existingData ?? "",
                      }}></div>
                    <div
                      className='border min-h-[80px] rounded-sm p-2'
                      dangerouslySetInnerHTML={{ __html: newData ?? "" }}></div>
                  </div>
                </>
              ),
            },
            {
              label: `Video`,
              key: "video",
              children: (
                <>
                  <div className='grid grid-cols-2 gap-5 mt-4'>
                    <div className='border min-h-[80px] rounded-sm p-2'>
                      {newVideo ? (
                        <BitmovinPlayer
                          dash={newVideo?.dash}
                          hls={undefined}
                          poster={undefined}
                        />
                      ) : (
                        <p>-- no old video content --</p>
                      )}

                    </div>
                    <div className='border min-h-[80px] rounded-sm p-2'>
                      {oldVideo ? (
                        <BitmovinPlayer
                          dash={oldVideo?.dash}
                          hls={undefined}
                          poster={undefined}
                        />
                      ) : (
                        <p>-- no new video content --</p>
                      )}
                    </div>
                  </div>
                </>
              ),
            },
            {
              label: `PDF`,
              key: "pdf",
              children: (
                <>
                  <div className='grid grid-cols-2 gap-5 mt-4'>
                    <div className='border min-h-[80px] rounded-sm p-2'>
                      {newPdf && newPdf.length > 0 ? (
                        newPdf.map((pdf) => (
                          <iframe
                            key={pdf.url}
                            src={pdf.url}
                            width='100%'
                            height='400px'
                            title='PDF Viewer'
                          />
                        ))
                      ) : (
                        <p>-- no new pdf content --</p>
                      )}
                    </div>
                    <div className='border min-h-[80px] rounded-sm p-2'>
                      {oldPdf && oldPdf.length > 0 ? (
                        oldPdf.map((pdf) => (
                          <iframe
                            key={pdf.url}
                            src={pdf.url}
                            width='100%'
                            height='400px'
                            title='PDF Viewer'
                          />
                        ))
                      ) : (
                        <p>-- no old pdf content --</p>
                      )}
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}
