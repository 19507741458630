import React from "react"
import { Button, Table } from "antd"
import { useTalentAnalytics } from "../hooks/useTalentAnalytics"
import dayjs from "dayjs"

const columns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",

  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    filters: [],
    onFilter: (value, record) => record.role.includes(value),
  },
  {
    title: "Location",
    dataIndex: "country",
    key: "country",
    filters: [],
    onFilter: (value, record) => record.country.includes(value),
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "points",
    sorter: true,
  },
  {
    title: "Courses",
    dataIndex: "total_bought_skill",
    key: "total_bought_skill",

  },
  {
    title: "Sales",
    dataIndex: "total_amount_spent",
    key: "total_amount_spent",

  },
  {
    title: "Joined",
    dataIndex: "created_at",
    key: "created_at", //account_creation_asc
    sorter: true,
    render: (date) => dayjs(date).format("YYYY-MM-DD"),
  },
  {
    title: "",
    dataIndex: "",
    width: "10%",
    render: () => <Button>View</Button>,
  },
]

export const TalentUsersTable = () => {
  const [param, setParam] = React.useState({
    page: 1,
    order_by: "account_points_asc",
    countries: undefined,
    roles: undefined,
  })


  const talentAnalytics = useTalentAnalytics({
    pageParam: param.page,
    search: null,
    order_by: param.order_by,
    countries: param.countries,
    roles: param.roles,
  })
  const talData = talentAnalytics.data?.data?.data


  const uniqueCountries = [
    ...new Set(
      talData?.users_data
        .map((user) => user.country)
        .filter((country) => country !== "")
    ),
  ]


  const uniqueRoles = [...new Set(talData?.users_data.map((user) => user.role))]


  columns[1].filters = uniqueRoles.map((role) => ({
    text: role,
    value: role,
  }))

  columns[2].filters = uniqueCountries.map((country) => ({
    text: country,
    value: country,
  }))


  const handleTableChange = (pagination, filters, sorter) => {
    console.log('sorter', sorter)
    const order = sorter.order === "ascend" ? "desc" : "asc"
    const orderBy = sorter.field === "created_at" ? `account_creation_${order}` : `account_${sorter.field}_${order}`
    const countries = filters.country ? filters.country : undefined
    const roles = filters.role ? filters.role : undefined

    setParam((prev) => ({
      ...prev,
      page: pagination.current,
      order_by: orderBy,
      countries: countries,
      roles: roles,
    }))
  }

  return (
    <>
      <div className="text-backdrop font-bold">
        Total: {talData?.total_data}
      </div>
      <div className="admin-table mt-6">
        <Table
          dataSource={talData?.users_data}
          columns={columns}
          loading={talentAnalytics.isLoading}
          pagination={{
            current: param.page,
            pageSize: 100,
            hideOnSinglePage: true,
            total: talData?.total_data,
          }}
          onChange={handleTableChange}
        />
      </div>
    </>
  )
}
